// Fonts
@import './fonts.scss';

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// ADMIN LTE
@import '~admin-lte/dist/css/adminlte.min.css';

// JQUERY UI
// @import '~jquery-ui-dist/jquery-ui.min.css';

// DATATABLES
@import '~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-autofill/css/autoFill.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-colreorder/css/colReorder.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-fixedcolumns/css/fixedColumns.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-fixedheader/css/fixedHeader.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-keytable/css/keyTable.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-rowgroup/css/rowGroup.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-rowreorder/css/rowReorder.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-scroller/css/scroller.bootstrap4.min.css';
// @import '~admin-lte/plugins/datatables-searchbuilder/css/searchBuilder.bootstrap4.min.css';
@import '~admin-lte/plugins/datatables-searchpanes/css/searchPanes.bootstrap4.min.css';
// @import '~admin-lte/plugins/datatables-select/css/select.bootstrap4.min.css';

// FULL CALENDAR
@import '~admin-lte/plugins/fullcalendar/main.min.css';

// CHARTJS
@import '~admin-lte/plugins/chart.js/Chart.min.css';

// BOOTSTRAP COLORPICKER
// @import '~admin-lte/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css';

// SELECT2
@import '~admin-lte/plugins/select2/css/select2.min.css';
@import '~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';

// ANIMATE CSS
@import '~animate.css';

body{
    font-family: 'Ubuntu', sans-serif;
}

// MONEY MONITOR
.money-monitor {
	font-family: 'Ubuntu Mono', monospace;
	font-size: 16px;
	font-weight: bold;
}

// FOCUS DELETE
*,*:focus{
    outline: none !important;
    box-shadow: none !important;
}
.dropdown-item.active, .dropdown-item:active{
	background-color: $yellow !important;
}

// .btn-warning:focus, .btn-warning.focus {
//     color: #fff;
// 	background-color: $yellow !important;
// 	border-color: $yellow !important;
//     outline: none !important;
//     box-shadow: none !important;
// }

.bg-passive{
	background-color: rgba($color: $gray, $alpha: 1) !important;
	&:hover{
		background-color: rgba($color: $gray, $alpha: .9) !important;
	}
}
.bg-active{
	background-color: rgba($color: $light, $alpha: 1) !important;
	&:hover{
		background-color: rgba($color: $light, $alpha: .9) !important;
	}
}
.bg-not-specified{
	background-color: rgba($color: $light, $alpha: 1) !important;
	&:hover{
		background-color: rgba($color: $light, $alpha: .9) !important;
	}
}
.icheck-warning{
	>input {
		&:first-child {
			&:checked+input[type=hidden]+label {
				&::before {
					background-color: $yellow !important;
					border-color: $yellow !important;
				}
			}
			&:checked+label {
				&::before {
					background-color: $yellow !important;
					border-color: $yellow !important;
				}
			}
		}
	}
}
.bg-gradient-warning {
    background: $yellow linear-gradient(180deg,$yellow-dark,$yellow) repeat-x !important;
	color: #1f2d3d;
}
.breadcrumb-item {
	&::before {
		color: $dark !important;
	}
}
.dark-mode {
    .sidebar-dark-primary {
        .nav-sidebar {
            >.nav-item {
                >.nav-link.active {
                    background-color: #3f6791;
                    color: #fff;
                }
            }
        }
    }
    .sidebar-light-primary {
        .nav-sidebar {
            >.nav-item {
                >.nav-link.active {
                    background-color: #3f6791;
                    color: #fff;
                }
            }
        }
    }
	.sidebar-dark-warning {
		.nav-sidebar {
			>.nav-item {
				>.nav-link.active {
					background-color: $yellow !important;
					color: $dark !important;
				}
			}
		}
	}
	.sidebar-light-warning {
		.nav-sidebar {
			>.nav-item {
				>.nav-link.active {
					background-color: $yellow !important;
					color: $dark !important;
				}
			}
		}
	}
}
.btn-outline-dark {
	&:focus {
		box-shadow: none !important;
	}
}
.btn-outline-dark.focus {
	box-shadow: none !important;
}
.btn {
	&:focus {
		outline: 0;
		box-shadow: none !important;
	}
}
.btn.focus {
	outline: 0;
	box-shadow: none !important;
}
.sidebar-dark-warning .nav-sidebar>.nav-item>.nav-link.active, .sidebar-light-warning .nav-sidebar>.nav-item>.nav-link.active {
    background-color:  $yellow !important;
    color: $dark !important;
}
.form-control:focus,.custom-file-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: $yellow!important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba($yellow, 25%);
}
.custom-file-input~.custom-file-label {
	&::after {
		content: "GÖZAT";
	}
}
.nav-pills {
	.nav-link.active {
		color: #fff;
		background-color: $yellow;
	}
	.show {
		>.nav-link {
			color: #fff;
			background-color: $yellow;
		}
	}
}
.select2-selection {
	overflow: hidden;
}
.select2-selection__rendered {
	white-space: normal;
	word-break: break-all;
}
table.dataTable.dtr-inline.collapsed {
	>tbody {
		>tr.parent {
			>td.dtr-control {
				&:before {
					content: "-";
					background-color: $red!important;
				}
			}
			>th.dtr-control {
				&:before {
					content: "-";
					background-color: $red!important;
				}
			}
		}
		>tr {
			>td.dtr-control {
				&:before {
					top: 50%;
					left: 5px;
					height: 1em;
					width: 1em;
					margin-top: -9px;
					display: block;
					position: absolute;
					color: white;
					border: .15em solid white;
					border-radius: 1em;
					box-shadow: 0 0 0.2em #444;
					box-sizing: content-box;
					text-align: center;
					text-indent: 0 !important;
					font-family: "Courier New",Courier,monospace;
					line-height: 1em;
					content: "+";
					background-color: $yellow!important;
				}
			}
			>th.dtr-control {
				&:before {
					top: 50%;
					left: 5px;
					height: 1em;
					width: 1em;
					margin-top: -9px;
					display: block;
					position: absolute;
					color: white;
					border: .15em solid white;
					border-radius: 1em;
					box-shadow: 0 0 0.2em #444;
					box-sizing: content-box;
					text-align: center;
					text-indent: 0 !important;
					font-family: "Courier New",Courier,monospace;
					line-height: 1em;
					content: "+";
					background-color: $yellow!important;
				}
			}
		}
	}
}

// PAGINATION
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: var(--dark) !important;
	background-color: #fff;
	border: 1px solid #dee2e6;
}
.page-item.active {
	.page-link {
		z-index: 3;
		color: #fff !important;
		background-color: var(--dark) !important;
		border-color: var(--dark) !important;
	}
}
@media screen and (max-width: 500px) {
    li.paginate_button.previous {
        display: inline;
    }
    li.paginate_button.next {
        display: inline;
    }
    li.paginate_button {
        display: none;
    }
}

// SELECT2
.select2-container--bootstrap4 {
	.select2-selection--multiple {
		.select2-selection__choice {
			color: $dark !important;
			background-color: $yellow !important;
		}
		.select2-selection__choice__remove {
			color: $dark !important;
			&:hover {
				color: $secondary !important;
			}
		}
	}
	.select2-selection__clear {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $dark !important;
		background-color: $yellow !important;
		border-radius: 0;
		padding-left: 0;
		&:hover {
			color: $secondary !important;
			background-color: $yellow !important;
		}
	}
}
.select2-container--bootstrap4.select2-container--focus {
	.select2-selection {
		border-color: $yellow;
		box-shadow: 0 0 0 0.2rem rgba($yellow, 25%);
	}
}

// DATATABLES
.datatable-top, .datatable-bottom{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
div.dataTables_wrapper div.dataTables_processing {
    background-color: $yellow;
	color: $white;
}

// CHECKBOX
[class*="icheck-"] > input:first-child + label::before, [class*="icheck-"] > input:first-child + input[type="hidden"] + label::before {
    content: "";
    border-color: $yellow !important;
}
